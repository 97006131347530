import React, { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faDownload } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import { useMutation } from "@apollo/react-hooks";
import { UserVerifyComponentProps, UserVerifyMutationData } from "../../types";
import {
  VERIFY_NOTIFICATION_EMAIL,
  MAGNET_VERIFY
} from "../../graphql/mutations";
import { Translate } from "../../components/translations";
import { setUserToken, setUserHash } from "../../helpers";
// import other from "../../images/lead-magnet/automation-playbook-3d.png";
// import eCommerce from "../../images/lead-magnet/ecommerce-playbook-3d.png";
// import agency from "../../images/lead-magnet/3-Agency-cover.png";

// import otherpdf from "../../../static/monitoring-strategy-playbook-all-industries.pdf";
// import eCommercepdf from "../../../static/ecommerce-strategy-playbook.pdf";
// import agencypdf from "../../../static/hexowatch-agency-usecases.pdf";

import "../styles.scss";
import { navigate } from "gatsby";

// const magnetFields = [
//   {
//     name: "eCommerce",
//     img: eCommerce,
//     pdf: eCommercepdf,
//     pdfName: 'Hexowatch-ecommerce-strategy-playbook.pdf'
//   },
//   {
//     name: "Agency",
//     img: agency,
//     pdf: agencypdf,
//     pdfName: 'Hexowatch-hexowatch-agency-usecases.pdf'
//   },
//   {
//     name: "Other",
//     img: other,
//     pdf: otherpdf,
//     pdfName: 'Hexowatch-monitoring-strategy-playbook-all-industries.pdf'
//   }
// ];

const VerifyNotificationEmail: FC<UserVerifyComponentProps> = ({ token }) => {
  const [verifyMutation, { data }] = useMutation(VERIFY_NOTIFICATION_EMAIL);
  // const [verifyMagnetMutation, { data: magnetData }] = useMutation(MAGNET_VERIFY);
  const [errorMessage, setError] = useState("");
  const [magnetField, setMagnetField] = useState("");

  useEffect(() => {
    setError("");
    verifyMutation({ variables: { verify_token: token } });
  }, []);

  useEffect(() => {
    setMagnetField("");
    setError("");
    if (
      data &&
      data.UserWatchSettingsOps &&
      data.UserWatchSettingsOps.verifyNotificationEmail
    ) {
      const {
        error,
        message
      } = data.UserWatchSettingsOps.verifyNotificationEmail;
      if (error) {
        setError(message);
      } else {
        navigate("/");
      }
    }
  }, [data]);

  // const selectedMagnetField = magnetFields.find(item => item.name === magnetField);

  // const handleDownloadPdf = () => {
  //   selectedMagnetField && FileSaver.saveAs(selectedMagnetField.pdf, selectedMagnetField.pdfName);
  // };

  return (
    <div className="content-wrapper">
      <PageHero
        title="VERIFY_SUBTITLE"
        subtitle={magnetField ? "Thank you for confirming your email." : ""}
      />
      <Layout>
        <Section>
          <div className="col-lg-12 text-center">
            {errorMessage ? (
              <div className="alert alert-danger">
                <Translate name={errorMessage} />
              </div>
            ) : (
              !magnetField && <FontAwesomeIcon icon={faSpinner} spin />
            )}
            {/* {magnetField && selectedMagnetField && 
              <div>
                <div
                  className="mt-4 download-link"
                >
                  <span  onClick={() => handleDownloadPdf()}>
                    <FontAwesomeIcon icon={faDownload} className="mr-2 f_size_12" />
                    Download Now
                  </span>
                </div>
                <img onClick={() => handleDownloadPdf()} style={{width: '400px', height: 'auto', cursor: 'pointer'}} src={selectedMagnetField.img} alt={`${selectedMagnetField.name} use cases`} />
              </div>
            } */}
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default VerifyNotificationEmail;
